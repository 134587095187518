import { getSession } from '@/services/api/helpers'
import {
  useRedirect,
  useNotify,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  FileField,
  FileInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'
import { Alert } from '@mui/material'
import { apiMultiVariantUpload } from '@/services/api/methods/apiMultiVariantUploads'

export interface Item {
  brandId: number
  variantId: number
  attachments: {
    rawFile?: File
    src?: string
    title?: string
  }
}

export interface FormDataInput {
  items?: Item[]
}
const VariantsMultiUpload = () => {
  const redirect = useRedirect()
  const notify = useNotify()
  const session = JSON.parse(getSession() || '{}')

  const validateArrayLength = (value: any) => {
    if (!value || value.length < 1) {
      return 'You must have at least one item.'
    }
    if (value.length > 5) {
      return 'You cannot have more than five items.'
    }

    // Validate each item for required fields
    for (let i = 0; i < value.length; i++) {
      const item = value[i]
      if (!item.brandId) {
        return `Item ${i + 1}: Brand is required.`
      }
      if (!item.variantId) {
        return `Item ${i + 1}: Variant is required.`
      }
      if (!item.attachments || !item.attachments.rawFile) {
        return `Item ${i + 1}: A valid file is required.`
      }
    }

    return undefined
  }

  const submit = (val: FormDataInput) => {
    apiMultiVariantUpload(val?.items)
      .then(() => {
        redirect('/variants')
      })
      .catch((err) => {
        notify(<Alert severity="error">{err.response.data?.message || 'Something went wrong!'}</Alert>)
      })
  }

  return (
    <>
      <h2>Upload Bulk Order</h2>
      <SimpleForm
        onSubmit={submit}
        sx={{
          m: 0,
          p: 0,
        }}
        defaultValues={{
          items: [{ brandId: null, variantId: 0, attachments: [] }],
        }}
      >
        <ArrayInput source="items" validate={validateArrayLength}>
          <SimpleFormIterator inline>
            <ReferenceInput source="brandId" reference="brands" perPage={1000} label="Brand Name">
              <SelectInput optionText="enName" />
            </ReferenceInput>
            <FormDataConsumer>
              {({ scopedFormData, getSource, ...rest }) =>
                scopedFormData?.brandId ? (
                  <ReferenceInput
                    source={getSource?.('variantId')} // Scoped to the current array item
                    reference="variants"
                    filter={{ brandId: scopedFormData.brandId }}
                    perPage={1000}
                    label="Variant"
                  >
                    <SelectInput
                      optionText={(choice: any) => {
                        const enName = choice?.value?.[0]?.enName || ''
                        const enValue = choice?.value?.[0]?.enValue || ''
                        const arName = choice?.value?.[0]?.arName || ''
                        const arValue = choice?.value?.[0]?.arValue || ''

                        return `${enName || enValue} - ${arName || arValue}`
                      }}
                      {...rest}
                    />
                  </ReferenceInput>
                ) : null
              }
            </FormDataConsumer>
            <FileInput
              placeholder="Drop CSV File"
              source="attachments"
              accept={'.csv'}
              maxSize={10000000}
              sx={{ width: '200px', px: '0' }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </>
  )
}

export default VariantsMultiUpload
